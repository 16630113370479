import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    DesktopStatistic: () =>
      import("./desktop/DesktopStatistic/DesktopStatistic.vue"),
    MobileStatistic: () =>
      import("./mobile/MobileStatistic/MobileStatistic.vue"),
  },
})
export default class Statistic extends Vue {}
